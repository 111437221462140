<template>
  <v-sheet
    color="secondary lighten-1"
    class="d-flex align-center justify-center px-4"
    height="100%"
  >
    <v-card
      color="primary darken-2"
      elevation="6"
      width="500px"
      height="450px"
      class="mt-n6"
    >
      <v-btn large class="mt-2 ml-2" icon :to="{name: 'home'}" style="position: absolute">
        <v-icon color="white">mdi-arrow-left</v-icon>
      </v-btn>
      <div class="fill-height d-flex flex-column justify-center">
        <v-img
          src="/tesys-white.png"
          max-height="80px"
          class="mx-auto mb-6"
          contain
        />
        <div class="px-4 pb-2 d-flex justify-end">
          <a
            class="white--text px-3"
            @click="$refs.contact.open()"
          >
            Quero me Cadastrar
          </a>
        </div>
        <v-form ref="loginForm" class="mx-6" @submit.prevent="login()">
          <v-text-field
            v-model="loginData.email"
            color="primary"
            label="E-Mail *"
            prepend-inner-icon="mdi-email"
            hide-details="auto"
            class="mb-5 white"
            filled
            :disabled="loadingLogin"
            :rules="[$rules.required, $rules.email]"
          />
          <v-text-field
            v-model="loginData.password"
            color="primary"
            :append-icon="seePassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="seePassword = !seePassword"
            :type="seePassword ? 'text' : 'password'"
            label="Senha *"
            class="mb-5 white"
            filled
            prepend-inner-icon="mdi-key-variant"
            hide-details="auto"
            :disabled="loadingLogin"
            :rules="[$rules.required, $rules.passwordMin]"
          />
          <div class="d-flex align-center mt-6">
            <v-btn
              small color="primary darken-1"
              class="py-5 px-4" depressed
              :to="{ name: 'forgot-password' }"
              :disabled="loadingLogin"
            >
              <v-icon left>mdi-lock-question</v-icon>
              Esqueci minha senha
            </v-btn>
            <v-spacer/>
            <v-btn
              large
              color="success"
              class="ml-2"
              :loading="loadingLogin"
              type="submit"
            >
              Entrar
            </v-btn>
          </div>
        </v-form>
      </div>
    </v-card>
    <Contact ref="contact" />
  </v-sheet>
</template>

<script>
import Contact from "@/components/login/Contact.vue";

export default {  
  name: "Login",
  components: {
    Contact,
  },
  data: () => ({
    seePassword: false,
    loading: false,
    loadingLogin: false,
    loginData: {
      email: null,
      password: "",
    },
  }),
  methods: {
    login() {
      if (this.$refs.loginForm.validate()) {
        this.loadingLogin = true;
        this.$axios
          .put("/login", this.loginData)
          .then((response) => {
            this.$store.commit("setUser", response.data);
            this.$router.replace({
              name: "app",
            });
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                case 401:
                  this.$showMessage("warning", "Senha Incorreta");
                  return;
                case 404:
                  this.$showMessage("warning", "E-Mail Incorreto");
                  return;
                case 423:
                  this.$showMessage("warning", "Esse Usuário foi Deletado");
                  return;
              }
            }
            this.$showMessage("error", "Falha Inesperada no Login");
          })
          .finally(() => {
            this.loadingLogin = false;
          });
      }
    },
  },
  created() {
    if (this.$store.state.logged) {
      this.$router.replace({ name: "app" });
    }
  },
};
</script>
