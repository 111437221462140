<template>
  <div>
    <v-dialog v-model="showing" max-width="750px" persistent>
      <v-card>
        <v-toolbar flat dark color="primary darken-2">
          <v-toolbar-title> Informações para contato </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon :disabled="loading" @click="showing = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-form ref="form" @submit.prevent="send()">
            <v-text-field
              v-model="lead.name"
              label="Nome *"
              hide-details="auto"
              color="primary"
              :rules="[$rules.required]"
              :disabled="loading"
            />
            <v-text-field
              v-model="lead.email"
              label="E-Mail *"
              hide-details="auto"
              class="mt-5 white white--text"
              color="primary"
              :rules="[$rules.required, $rules.email]"
              :disabled="loading"
            />
            <v-text-field
              v-model="lead.phone"
              label="Telefone *"
              hide-details="auto"
              class="mt-5 white white--text"
              color="primary"
              v-mask="['(##) ####-####','(##) #####-####']"
              placeholder="(00) 0000-0000"
              :rules="[$rules.required, $rules.phone]"
              :disabled="loading"
            />
            <div class="mt-5 d-flex align-center">
              <v-checkbox
                class="pa-0 ma-0"
                label="Concordo com receber comunicações sobre o Tesys"
                hide-details="auto"
                :rules="[(v) => !!v || 'Você deve concordar para continuar']"
                :disabled="loading"
              />
              <v-spacer/>
              <v-btn
                color="primary"
                class="ml-4"
                :loading="loading"
                type="submit"
              >
                Solicitar contato
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="400px" persistent>
      <v-card color="">
        <v-card-title class="success white--text">
          Obrigado por seu interesse
        </v-card-title>
        <v-card-text class="my-4 py-0">
          Em breve nossa equipe entrará em contato para mais informações
        </v-card-text>
        <v-card-actions class="pt-0 mt-0">
          <v-spacer />
          <v-btn text @click="successDialog = false">
            entendi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RegisterContactDialog",

  data: () => ({
    showing: false,
    loading: false,
    successDialog: false,
    lead: {},
  }),

  methods: {
    open() {
      this.showing = true;
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      }
    },
    send(){
      if (this.$refs.form.validate()){
        this.loading = true;
        this.$axios.post('/lead', this.lead)
          .then(() => {
            this.showing = false;
            this.successDialog = true;
          })
          .catch(() => {
            this.$showMessage('error', "Erro inesperado ao salvar as informações");
          })
          .finally(() => {
            this.loading = false;
          })
      }
    },
  },
};
</script>
